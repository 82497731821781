import React from 'react'

function Footer() {
  return (
    <div className='py-10'>
        {/* nak buat line horizontal letak height 1px and width jadikan full */}
        {/* kalau nak buat vertical terbalik */}
      <div className='h-[1px] w-full bg-gray-500'>

      </div>
      <div className='flex items-center justify-center flex-col mt-10 opacity-70'>
        <h1 className="text-white">
            Designed and Developed by
        </h1>
        <h1 className="text-white">
             Fadhil
        </h1>
      </div>
    </div>
  )
}

export default Footer
