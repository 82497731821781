import React from "react";
import SectionTitle from "../../components/SectionTitle";
import { useSelector } from "react-redux";

function Aboutme() {
  // const skills = ["AWS"]
    const { portfolioData } = useSelector((state) => state.root);
    const skills = portfolioData.about[0].skills;
    // console.log(portfolioData.about[0].skills);
    const image = portfolioData.about[0].imageURL;
  return (
    <div id="about-me" className="py-10">
      <SectionTitle title="About Me" />
      <div className="flex items-center sm:flex-col">
        <div className="py-40 h-[40vh] w-full flex justify-center items-center">
          {/* <dotlottie-player
            src="https://lottie.host/03d88644-1db1-4811-aed6-8e8b6f5c6b22/Oxt9ov30e5.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></dotlottie-player> */}
          <img src= {image} alt="" className='max-h-[250px] min-w-[200px] sm:max-h-[250px] sm:max-w-[300px]'/>
        </div>
        <div className="flex flex-col gap-5">
            <p className="text-white px-5 text-justify">I’m a passionate backend developer with a strong focus on building efficient, scalable, and secure server-side applications. 
                With a solid foundation in various backend technologies, including <span className="font-sans" style={{color:"#FF5733"}}>Node.js</span>, <span className="font-sans" style={{color:"#FF5733"}}>Python</span>, and 
                <span className="font-sans"style={{color:"#FF5733"}}> C#</span>, I specialize in designing robust APIs, managing databases, 
                and optimizing server performance. My experience extends 
                to working with cloud platforms, microservices architecture, and CI/CD pipelines, ensuring smooth deployments and seamless integrations.</p>
            <p className="text-white px-5 text-justify">I enjoy solving complex problems and thrive in environments that require creativity and logical thinking. 
                Constantly staying up-to-date with industry trends, I strive to implement the best practices in software development, maintaining high code quality and 
                performance.</p>
        </div>
      </div>

      <div>
        <h1 className="text-tertiary text-2xl mt-5">Here are a few technologies I've been working with recently:</h1>

        <div className="flex flex-wrap gap-10 mt-5">
        {skills.map((skill, index) => (
            <div 
            key={skill._id || index}
            className="border border-tertiary py-3 px-5">
                <h1 className="text-tertiary">{skill}</h1>
            </div>
         ))}
        </div>
         
      </div>
    </div>
  );
}

export default Aboutme;
