import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import Loader from "./components/Loader";
import axios from "axios";
import {
  HideLoading,
  ReloadData,
  SetPortfolioData,
  ShowLoading,
} from "./redux/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import Admin from "./pages/Admin";
import Login from "./pages/Admin/AdminLogin";

function App() {
  //loader
  // const [showloading, setshowloading] = useState(false);

  //portfolioData is the data. This is coming from the reducer
  const { loading, portfolioData, reloadData } = useSelector(
    (state) => state.root
  );

  const dispatch = useDispatch();
  const getPortfolioData = async () => {
    try {
      dispatch(ShowLoading(true));
      const response = await axios.get(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/get-portfolio-data"
      );
      //http://localhost:5000/api/portfolio/get-portfolio-data
      //https://plustmcapis.serasi.tech/api/get-portfolio-data
      //kat sini guna dispatch to trigger actions/functions from reducer
      dispatch(SetPortfolioData(response.data));
      dispatch(ReloadData(false));
      dispatch(HideLoading());
    } catch (error) {
      console.log(error);
      dispatch(HideLoading());
    }
  };

  //this part act macam event listener
  //maksud dia if portfolioData empty request getPortfolioData()
  useEffect(() => {
    if (!portfolioData) {
      getPortfolioData();
    }
  }, [portfolioData]);

  //reload data whenever there is update from user
  useEffect(() => {
    if (reloadData) {
      getPortfolioData();
    }
  }, [reloadData]);

  return (
    //this is browser return
    <BrowserRouter>
      {/* ni maksud dia check if showloading is not null display Loader else display null */}
      {loading ? <Loader /> : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
