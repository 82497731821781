import React from "react";
import SectionTitle from "../../components/SectionTitle";

function Contact() {
  const user = {
    name: "Muhammad Fadhil Iman Bin Zarai",
    email: "fadhil448@gmail.com",
    age: null,
    gender: "Male",
    mobile: "0172996985",
    country: "Malaysia",
  };
  return (
    <div>
      <SectionTitle title="Say Hello" />
      <div className="flex mt-5 justify-start gap-20 sm:flex-col items-center">
        <div className="flex flex-col gap-1">
          <h1 className="text-white text-sm">{"{"}</h1>
          {Object.keys(user).map((key) => (
            <h1 key={key}className="ml-5">
              <span className="text-white">{key}</span> :{" "}
              <span className="text-white">{user[key]}</span>
            </h1>
          ))}
          <h1 className="text-white">{"}"}</h1>
        </div>
        {/* <div className="h-[100px]">
            <dotlottie-player
            src="https://lottie.host/aee85f01-ea72-42d3-a9c0-09be301896c3/VSSKPNErlJ.json"
            background="transparent"
            speed="1"
            autoplay
            ></dotlottie-player>
        </div> */}
      </div>
    </div>
  );
}

export default Contact;
