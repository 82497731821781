import React from 'react'

function Header() {
  return (
    //p-5 is padding 20 from all sides with property flex
    <div className='header p-5 bg-primary flex justify-between w-full'>
      <h1 className='text-secondary text-4xl font-semibold'>F</h1>
      <h1 className='text-tertiary text-4xl font-semibold'>I</h1>
    </div>
  )
}

export default Header
