import React from "react";
import { Button, Form, Input, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";

function AdminAbout() {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      console.log(e);
      return e;
    }
    return e?.fileList;
  };
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  // console.log(portfolioData);
  const about = portfolioData.about[0];
  const skills = about.skills.join(",")
  const onFinish = async (values) => {
    // console.log(values);
    try {
      if (values.skills != null){
        const tempSkills = values.skills.split(","); // kat sini baru split string guna comma (,)
        values.skills = tempSkills;
      }
      // console.log(values.skills); //sini dia still string sebab dari initial value set kat bawah line ~60
      
      
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/update-about",
        {
          //spread syntax - means dia akan spread elements dalam array
          ...values,
          _id: about._id,
        }
      );
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // const [form2] = Form.useForm(); study this. it is using useForm hooks
  const { TextArea } = Input;
  return (
    <Form
      // form={form2}
      name="about"
      //nak letak label kat atas. kalau letak tepi kiri guna horizontal
      layout="vertical"
      // initialValues={about}
      initialValues={{
        ...about,
        //join ni amik each item in array then gabungkan jadi satu string in this case guna comma (,)
        //so kena make sure dia dalam satu string not array sebab nanti nak split
      skills: skills,
    }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      {/* <Form.Item
        label="Upload"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload action="/upload.do" listType="picture-card">
          <button
            style={{
              border: 0,
              background: "none",
            }}
            type="button"
          >
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </button>
        </Upload>
      </Form.Item> */}


      <Form.Item
        label="Image"
        name="imageURL"
        rules={[
          {
            required: true,
            message: "Please input your Image URL!",
          },
        ]}
      >
        <Input placeholder="Image" />
      </Form.Item>

      <Form.Item
        label="First Description"
        name="description1"
        rules={[
          {
            required: true,
            message: "Please input your First Description!",
          },
        ]}
      >
        <TextArea rows={5} placeholder="First Description" />
        {/* <Input placeholder="Description"/> */}
      </Form.Item>

      <Form.Item
        label="Second Description"
        name="description2"
        rules={[
          {
            required: true,
            message: "Please input your Second Description!",
          },
        ]}
      >
        <TextArea rows={5} placeholder="Second Description" />
        {/* <Input placeholder="Description"/> */}
      </Form.Item>

      <Form.Item
        label="Skills"
        name="skills"
        rules={[
          {
            required: true,
            message: "Please input your Skills!",
          },
        ]}
      >
        <TextArea rows={2} placeholder="Skills" />
        {/* <Input placeholder="Description"/> */}
      </Form.Item>

      <Form.Item
        // wrapperCol={{
        //   offset: 15,
        //   span: 16,
        // }}
        style={{ display: "flex", justifyContent: "center" }} // Flexbox for centering
      >
        <Button type="default" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AdminAbout;
