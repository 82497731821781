import React from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";

function AdminIntro() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const intro = portfolioData.intro[0];
  const onFinish = async (values) => {
    // console.log(values);
    try {
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/update-intro",
        {
          ...values,
          _id: intro._id,
        }
        // { timeout: 5000 } // set timeout to 5 seconds
      );
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
    // console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // const [form] = Form.useForm();
  const { TextArea } = Input;
  return (
    <Form
      // form={form}
      name="intro"
      //nak letak label kat atas. kalau letak tepi kiri guna horizontal
      layout="vertical"
      initialValues={intro}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      <Form.Item
        label="Intro"
        name="welcomeText"
        rules={[
          {
            required: true,
            message: "Please input your Intro!",
          },
        ]}
      >
        <Input placeholder="Intro" />
      </Form.Item>

      <Form.Item
        label="First Name"
        name="firstName"
        rules={[
          {
            required: true,
            message: "Please input your First Name!",
          },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        label="Caption"
        name="caption"
        rules={[
          {
            required: true,
            message: "Please input your Caption!",
          },
        ]}
      >
        <Input placeholder="Caption" />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input your Description!",
          },
        ]}
      >
        <TextArea rows={5} placeholder="Description" />
        {/* <Input placeholder="Description"/> */}
      </Form.Item>

      <Form.Item
        // wrapperCol={{
        //   offset: 15,
        //   span: 16,
        // }}
        style={{ display: "flex", justifyContent: "center" }} // Flexbox for centering
      >
        <Button type="default" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AdminIntro;
