import React from "react";
import SectionTitle from "../../components/SectionTitle";
// import { experiences } from "../../resources/experiences_data";
import { useSelector } from "react-redux";

function Experiences() {
  //at 1:17:45 https://www.youtube.com/watch?v=dHgvIbvK1zM&t=463s
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const { portfolioData } = useSelector((state) => state.root);
  const { experiences } = portfolioData;
  console.log(experiences);
  return (
    <div>
      <SectionTitle title="Experiences" />
      <div className="flex py-10 justify-start items-start sm:flex-col sm:gap-5">
        <div className="flex flex-col gap-5 border-l-2 w-[180px] border-[#2c6f82] sm:flex-row sm:overflow-x-scroll sm:w-full sm:border-primary">
          {experiences.map((experience, index) => (
            <div
              key={experience._id || index}
              onClick={() => {
                setSelectedItemIndex(index);
              }}
              //   untuk user click kena pakai cursor-pointer
              className="cursor-pointer"
            >
              <h1
                className={`text-1/2xl px-2 ${
                  selectedItemIndex === index
                    ? "text-tertiary border-tertiary border-l-4 bg-[#1c4857] py-2"
                    : "text-white"
                }`}
              >
                {experience.period}
              </h1>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-start items-start mx-auto w-1/2 sm:w-full sm:ml-0">
          <h1 className="text-secondary text-xl">
            {experiences[selectedItemIndex].title}
          </h1>
          <h1 className="text-tertiary text-xl">
            {experiences[selectedItemIndex].company}
          </h1>
          <p className="text-white text-justify">
            {experiences[selectedItemIndex].description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Experiences;
