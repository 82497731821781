//at 3:34:00 https://www.youtube.com/watch?v=dHgvIbvK1zM&t=463s
import { createSlice } from "@reduxjs/toolkit";

const rootSlice = createSlice({
  name: "root",
  initialState: {
    loading: false,
    portfolioData: null,
    reloadData: false,
  },
  //three reducers
  //The reducer responds to the action, takes the current state, and creates new state based on the action payload
  //The 'state' is the current state of this slice, and the 'action' contains the type and the payload.
  reducers: {
    //ni semua functions
    ShowLoading: (state, action) => {
      state.loading = true;
    },
    HideLoading: (state, action) => {
      state.loading = false;
    },
    //will call whenever we get data from backend
    SetPortfolioData: (state, action) => {
      state.portfolioData = action.payload;
    },
    ReloadData: (state, action) => {
      state.reloadData = action.payload;
    },
  },
});

export default rootSlice.reducer;
export const { ShowLoading, HideLoading, SetPortfolioData, ReloadData } = rootSlice.actions;
