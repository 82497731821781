import React from "react";
import SectionTitle from "../../components/SectionTitle";
// import { projects } from "../../resources/projects_data";
import { useSelector } from "react-redux";

function Projects() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const { portfolioData } = useSelector((state) => state.root);
  const { projects } = portfolioData;
  // console.log(projects);
  return (
    <div>
      <SectionTitle title="Projects" />
      <div className="flex py-10 justify-start items-start sm:flex-col sm:gap-5">
        <div className="flex flex-col gap-5 border-l-2 w-[170px] border-[#2c6f82] sm:flex-row sm:overflow-x-scroll sm:w-full sm:border-primary">
          {projects.map((project, index) => (
            <div
              key={project._id || index}
              onClick={() => {
                setSelectedItemIndex(index);
              }}
              //   untuk user click kena pakai cursor-pointer
              className="cursor-pointer"
            >
              <h1
                className={`text-1/2xl px-2 ${
                  selectedItemIndex === index
                    ? "text-tertiary border-tertiary border-l-4 bg-[#1c4857]"
                    : "text-white"
                }`}
              >
                {project.title}
              </h1>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-center items-center mx-auto w-1/2 gap-5 sm:w-full sm:ml-0">
          <img
            src={projects[selectedItemIndex].image}
            alt=""
            className="h-24 w-30"
          />
          <div className="flex flex-col">
            <h1 className="text-secondary text-xl">
              {projects[selectedItemIndex].company}
            </h1>
            <p className="text-white text-justify">
              {projects[selectedItemIndex].description}
            </p>
            <br />
            <a
              className="link-style"
              href={projects[selectedItemIndex].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {projects[selectedItemIndex].link}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
