import React from "react";

function LeftSider() {
  return (
    // boleh guna sm:hidden kalau nak hide @ guna sm:static cam ni then tukar flex jadi horizontal guna flex-row dkt 3rd div
    <div className="fixed left-0 bottom-0 px-10 sm:static">
      <div className="flex flex-col gap-3 items-center">
        <div className="flex flex-col gap-3 sm:flex-row">
          {/* target blank is used to open on a new tab and rel"noopener noreferrer" is for security reason 
              to prevent potential security vulnerabilities (e.g., phishing attacks).*/}
          <a href="https://www.linkedin.com/in/fadhil-iman-61a5a4236/" target="_blank" rel="noopener noreferrer">
            <i class="ri-linkedin-fill text-gray-400 text-xl"></i>
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <i class="ri-facebook-circle-line text-gray-400 text-xl"></i>
          </a>
          <a href="mailto:fadhil448@gmail.com" target="_blank" rel="noopener noreferrer">
            <i class="ri-mail-line text-gray-400 text-xl"></i>
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <i class="ri-instagram-line text-gray-400 text-xl"></i>
          </a>
          <a href="https://github.com/Fadhil-Iman" target="_blank" rel="noopener noreferrer">
            <i class="ri-github-fill text-gray-400 text-xl"></i>
          </a>
        </div>
        <div className="w-[1px] h-32 bg-[#2676b8] sm:hidden"></div>
      </div>
    </div>
  );
}

export default LeftSider;
