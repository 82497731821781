import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/rootSlice";

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState({
    username: "",
    password: "",
  });
  async function handleLogin() {
    try {
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/admin-login",
        username
      );

      if (response.data.success) {
        dispatch(HideLoading());
        message.success(response.data.message);
        localStorage.setItem("token", response.data);
        window.location.href = "/admin";
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      console.log(error);
      message.error(error.message);
    }
  }
  return (
    <div className="flex justify-center items-center h-screen bg-primary">
      <div className="w-96 flex gap-5 p-5 shadow-2xl border border-gray-500 flex-col bg-white">
        <h1 className="text-2xl font-bold flex justify-center m-3">
          Portfolio - Admin Login
        </h1>
        <hr className="border-gray-300" />
        <input
          type="text"
          placeholder="Username"
          value={username.username}
          onChange={(e) =>
            setUsername({ ...username, username: e.target.value })
          }
        />
        <input
          type="password"
          placeholder="Password"
          value={username.password}
          onChange={(e) =>
            setUsername({ ...username, password: e.target.value })
          }
        />
        <div
          className="flex justify-center"
          onKeyDown={(e) => e.key === "Enter" && handleLogin()}
          tabIndex="0"
        >
          <button
            onClick={handleLogin}
            className="bg-blue-500 text-white p-2 w-full"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
