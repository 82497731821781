import React from "react";
import { useSelector } from "react-redux";

function Intro() {
  const { loading, portfolioData } = useSelector((state) => state.root);
  console.log(portfolioData);
  const { intro } = portfolioData;
  const { welcomeText, firstName, description, caption } = intro[0];
  return (
    //for every section we will give 80vh
    //add gap between elements h1
    <div className="intro h-[90vh] bg-primary flex flex-col items-start justify-center gap-8">
      <h1 className="text-white">{welcomeText || ""}</h1>
      <h1 className="text-7xl sm:text-3xl text-secondary">{firstName || ""}</h1>
      <h1 className="text-6xl sm:text-3xl text-white">{description || ""}</h1>
      <h1 className="text-4xl sm:text-3xl text-white">{caption || ""}</h1>
      {/* <p className='text-white'>Welcome to my portfolio</p> */}
      <a href="#about-me">
        <button className="border-2 border-tertiary text-tertiary px-10 py-3 rounded">
          Get Started
        </button>
      </a>
    </div>
  );
}

export default Intro;
