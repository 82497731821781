import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, message } from "antd";
import { HideLoading, ReloadData, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";

function AdminExperiences() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { experiences } = portfolioData;
  // console.log(experiences);
  //Modal implementation - a window pop up
  const [isModalOpen, setIsModalOpen] = useState(false);
  //ni untuk beza add experience or edit experience
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  //flag at 5:18:20 https://www.youtube.com/watch?v=dHgvIbvK1zM
  // const [type, setType] = useState("add");
  const [form] = Form.useForm(); // Ant Design form instance

  //onFinish ni sama dengan dekat adminintro dengan adminabout
  const onFinish = async (values) => {
    let response;
    // console.log(values);
    //for add experience
    try {
      dispatch(ShowLoading());
      if (selectedItemForEdit) {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/update-experience",
          {
            ...values,
            _id: selectedItemForEdit._id,
          }
        );
      } else {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/add-experience",
          {
            values,
          }
        );
      }
      setIsModalOpen(false);
      dispatch(HideLoading());
      if (response.data.success) {
        console.log(response.data.data);
        message.success(response.data.message);
        setIsModalOpen(false);
        //kena tambah this one kalau tak nanti dia amik previous value bila tekan edit atau add experience
        setSelectedItemForEdit(null);
        dispatch(ReloadData(true));
        dispatch(HideLoading());
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/delete-experience",
        {
          _id: item._id,
        }
      );
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleEdit = (experience) => {
    setSelectedItemForEdit(experience);
    setIsModalOpen(true);
    form.setFieldsValue(experience); // Set the form values to the selected experience
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedItemForEdit(null); // Clear selection after closing modal
      form.resetFields(); // Reset form fields
    }
  }, [isModalOpen, form]);
  return (
    <div>
      <div className=" grid gap-10">
        <div className="flex justify-between gap-5">
          <h1>*Make sure to put latest experience at the top</h1>
          <button
            className="bg-blue-500 text-white px-5 py-3 mr-8"
            onClick={() => {
              form.resetFields(); // Reset the form to clear previous data
              // setSelectedItemForEdit(null); // Clear any previous selection
              setIsModalOpen(true);
            }}
          >
            Add Experience
          </button>
        </div>
        {experiences.map((experience, index) => (
          <div
            key={experience._id || index}
            className="shadow border p-4 border-gray-500"
          >
            <h1 className="text-secondary text-xl py-2">{experience.period}</h1>
            {/* horizontal line hr */}
            <hr />
            <h1 className="text-justify">Company: {experience.company}</h1>
            <h1>Title: {experience.title}</h1>
            <h1 className="text-justify">
              Description: {experience.description}
            </h1>
            <div className="flex justify-end gap-3">
              <button
                className="bg-red-500 text-white px-5 py-2"
                onClick={() => {
                  onDelete(experience);
                }}
              >
                Delete
              </button>
              <button
                className="bg-primary text-white px-5 py-2"
                onClick={() => {
                  // setSelectedItemForEdit(experience);
                  // setIsModalOpen(true);
                  // setType("edit");
                  handleEdit(experience);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* if the type is add or the data is present in the modal then only we are going to show it */}
      <Modal
        //if not null/ use Edit Experience else Add Experience SEBAB ADA "?" which is used to check for null value
        title={selectedItemForEdit ? "Edit Experience" : "Add Experience"}
        open={isModalOpen}
        //kena buang footer sebab ada button cancel ok which is not for form but for the modal
        //instead we have to create our own button
        footer={false}
        // onOk={handleOk}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedItemForEdit(null);
          form.resetFields(); // Reset the form to clear previous data
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={
            selectedItemForEdit || {
              period: "",
              company: "",
              title: "",
              description: "",
            }
          }
        >
          <Form.Item name="period" label="Period">
            <input placeholder="Period" />
          </Form.Item>
          <Form.Item name="company" label="Company">
            <input placeholder="Company" />
          </Form.Item>
          <Form.Item name="title" label="Title">
            <input placeholder="Title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <textarea
              placeholder="Description"
              style={{
                height: "100px",
                width: "100%",
                padding: "8px", // Same padding as input
                border: "1px solid #000", // Default input border color
                borderRadius: "4px", // Default input border radius
                fontSize: "14px", // Match input font size
              }}
            />
          </Form.Item>
          <div className="flex justify-end gap-3">
            <button className="bg-green-400 px-5 py-2">
              {selectedItemForEdit ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AdminExperiences;
