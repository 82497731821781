import React from 'react'

function Loader() {
  return (
    //this loader should be on top of all components so we need to use fixed
    <div className='h-screen flex items-center justify-center fixed inset-0 bg-primary sm:text-3xl z-[100]'>
      <div className='flex gap-5 text-4xl'>
        <h1 className="text-secondary c">Loading</h1>
        <h1 className="text-white f">Page</h1>
        <h1 className="text-tertiary l">...</h1>
      </div>
    </div>
  )
}

export default Loader
