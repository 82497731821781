import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, message } from "antd";
import { HideLoading, ReloadData, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";

function AdminProjects() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { projects } = portfolioData;
  console.log(projects);
  //Modal implementation - a window pop up
  const [isModalOpen, setIsModalOpen] = useState(false);
  //ni untuk beza add project or edit project
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  //flag at 5:18:20 https://www.youtube.com/watch?v=dHgvIbvK1zM
  // const [type, setType] = useState("add");
  const [form] = Form.useForm(); // Ant Design form instance

  //onFinish ni sama dengan dekat adminintro dengan adminabout
  const onFinish = async (values) => {
    let response;
    // console.log(values);
    //for add project
    try {
      dispatch(ShowLoading());
      if (selectedItemForEdit) {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/update-project",
          {
            ...values,
            _id: selectedItemForEdit._id,
          }
        );
      } else {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/admin-login",
          {
            values,
          }
        );
      }
      setIsModalOpen(false);
      dispatch(HideLoading());
      if (response.data.success) {
        console.log(response.data.data);
        message.success(response.data.message);
        setIsModalOpen(false);
        //kena tambah this one kalau tak nanti dia amik previous value bila tekan edit atau add project
        setSelectedItemForEdit(null);
        dispatch(ReloadData(true));
        dispatch(HideLoading());
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/delete-project",
        {
          _id: item._id,
        }
      );
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleEdit = (project) => {
    setSelectedItemForEdit(project);
    setIsModalOpen(true);
    form.setFieldsValue(project); // Set the form values to the selected project
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedItemForEdit(null); // Clear selection after closing modal
      form.resetFields(); // Reset form fields
    }
  }, [isModalOpen, form]);
  return (
    <div>
      <div className=" grid gap-10">
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-5 py-3 mr-8"
            onClick={() => {
              form.resetFields(); // Reset the form to clear previous data
              // setSelectedItemForEdit(null); // Clear any previous selection
              setIsModalOpen(true);
            }}
          >
            Add Project
          </button>
        </div>
        <div className="grid grid-cols-2 gap-5">
          {projects.map((project, index) => (
            <div
              key={project._id || index}
              className="shadow border p-4 border-gray-600 flex flex-col gap-5"
            >
              <h1 className="text-secondary text-xl py-2">{project.title}</h1>
              <hr /> {/* horizontal line hr */}
              <img src={project.image} alt="" className="h-60 w-80 mx-auto" />
              <h1 className="text-justify">Company: {project.company}</h1>
              <h1 className="text-justify">Title: {project.title}</h1>
              <h1 className="text-justify">
                Description: {project.description}
              </h1>
              <div className="flex justify-end gap-3 mt-5">
                <button
                  className="bg-red-500 text-white px-5 py-2"
                  onClick={() => {
                    onDelete(project);
                  }}
                >
                  Delete
                </button>
                <button
                  className="bg-primary text-white px-5 py-2"
                  onClick={() => {
                    // setSelectedItemForEdit(project);
                    // setIsModalOpen(true);
                    // setType("edit");
                    handleEdit(project);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* if the type is add or the data is present in the modal then only we are going to show it */}
      <Modal
        //if not null/ use Edit Experience else Add Experience SEBAB ADA "?" which is used to check for null value
        title={selectedItemForEdit ? "Edit Experience" : "Add Experience"}
        open={isModalOpen}
        //kena buang footer sebab ada button cancel ok which is not for form but for the modal
        //instead we have to create our own button
        footer={false}
        // onOk={handleOk}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedItemForEdit(null);
          form.resetFields(); // Reset the form to clear previous data
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={
            selectedItemForEdit || {
              period: "",
              company: "",
              title: "",
              description: "",
            }
          }
        >
          <Form.Item name="image" label="Image">
            <input placeholder="Image" />
          </Form.Item>
          <Form.Item name="company" label="Company">
            <input placeholder="Company" />
          </Form.Item>
          <Form.Item name="title" label="Title">
            <input placeholder="Title" />
          </Form.Item>
          <Form.Item name="link" label="Project Link">
            <input placeholder="Project Title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <textarea
              placeholder="Description"
              style={{
                height: "100px",
                width: "100%",
                padding: "8px",
                border: "1px solid #000",
              }}
            ></textarea>
          </Form.Item>
          <div className="flex justify-end gap-3">
            <button className="bg-green-400 px-5 py-2">
              {selectedItemForEdit ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AdminProjects;
