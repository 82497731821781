import React from "react";
import SectionTitle from "../../components/SectionTitle";
// import { courses } from "../../resources/courses_data";
import { useSelector } from "react-redux";

function Courses() {
  //set default to 0 on useState
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const { portfolioData } = useSelector((state) => state.root);
  const { courses } = portfolioData;
  console.log(courses);
  return (
    <div>
      <SectionTitle title="Courses" />
      <div className="flex py-10 justify-start items-start sm:flex-col sm:gap-5">
        <div className="flex flex-col gap-5 border-l-2 w-[170px] border-[#2c6f82] sm:flex-row sm:overflow-x-scroll sm:w-full sm:border-primary">
          {courses.map((course, index) => (
            <div
              key={course._id || index}
              onClick={() => {
                setSelectedItemIndex(index);
              }}
              //   untuk user click kena pakai cursor-pointer
              className="cursor-pointer"
            >
              <h1
                className={`text-1/2xl px-2 ${
                  selectedItemIndex === index
                    ? "text-tertiary border-tertiary border-l-4 bg-[#1c4857] py-2"
                    : "text-white"
                }`}
              >
                {course.title}
              </h1>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-center items-center w-1/2 mx-auto gap-5 sm:w-full sm:ml-0">
          <img
            src={courses[selectedItemIndex].image}
            alt=""
            className="h-24 w-30"
          />
          <div>
            {/* <h1 className="text-secondary text-xl">{courses[selectedItemIndex].company}</h1> */}
            <p className="text-white text-justify">
              {courses[selectedItemIndex].description}
            </p>
            <br />
            <a
              className="link-style inline-block"
              href={courses[selectedItemIndex].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {courses[selectedItemIndex].link}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
