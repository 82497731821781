import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, message } from "antd";
import { HideLoading, ReloadData, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";

function AdminCourses() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { courses } = portfolioData;
  console.log(courses);
  //Modal implementation - a window pop up
  const [isModalOpen, setIsModalOpen] = useState(false);
  //ni untuk beza add course or edit course
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  //flag at 5:18:20 https://www.youtube.com/watch?v=dHgvIbvK1zM
  // const [type, setType] = useState("add");
  const [form] = Form.useForm(); // Ant Design form instance

  //onFinish ni sama dengan dekat adminintro dengan adminabout
  const onFinish = async (values) => {
    let response;
    // console.log(values);
    //for add course
    try {
      dispatch(ShowLoading());
      if (selectedItemForEdit) {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/update-course",
          {
            ...values,
            _id: selectedItemForEdit._id,
          }
        );
      } else {
        response = await axios.post(
          "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/add-course",
          {
            values,
          }
        );
      }
      setIsModalOpen(false);
      dispatch(HideLoading());
      if (response.data.success) {
        console.log(response.data.data);
        message.success(response.data.message);
        setIsModalOpen(false);
        //kena tambah this one kalau tak nanti dia amik previous value bila tekan edit atau add course
        setSelectedItemForEdit(null);
        dispatch(ReloadData(true));
        dispatch(HideLoading());
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post(
        "https://4pcqdf0ab9.execute-api.ap-southeast-1.amazonaws.com/dev/delete-course",
        {
          _id: item._id,
        }
      );
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleEdit = (course) => {
    setSelectedItemForEdit(course);
    setIsModalOpen(true);
    form.setFieldsValue(course); // Set the form values to the selected course
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedItemForEdit(null); // Clear selection after closing modal
      form.resetFields(); // Reset form fields
    }
  }, [isModalOpen, form]);
  return (
    <div>
      <div className=" grid gap-10">
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-5 py-3 mr-8"
            onClick={() => {
              form.resetFields(); // Reset the form to clear previous data
              // setSelectedItemForEdit(null); // Clear any previous selection
              setIsModalOpen(true);
            }}
          >
            Add Course
          </button>
        </div>
        {courses.map((course, index) => (
          <div
            key={course._id || index}
            className="shadow border p-4 border-gray-500"
          >
            <h1 className="text-secondary text-xl py-2">{course.title}</h1>
            {/* horizontal line hr */}
            <hr />
            <img src={course.image} alt="" className="w-20 h-20"/>
            <h1 className="text-justify">Image: {course.link}</h1>
            <h1>Course: {course.title}</h1>
            <h1 className="text-justify">
              Description: {course.description}
            </h1>
            <div className="flex justify-end gap-3">
              <button
                className="bg-red-500 text-white px-5 py-2"
                onClick={() => {
                  onDelete(course);
                }}
              >
                Delete
              </button>
              <button
                className="bg-primary text-white px-5 py-2"
                onClick={() => {
                  // setSelectedItemForEdit(course);
                  // setIsModalOpen(true);
                  // setType("edit");
                  handleEdit(course);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* if the type is add or the data is present in the modal then only we are going to show it */}
      <Modal
        //if not null/ use Edit Experience else Add Experience SEBAB ADA "?" which is used to check for null value
        title={selectedItemForEdit ? "Edit Experience" : "Add Experience"}
        open={isModalOpen}
        //kena buang footer sebab ada button cancel ok which is not for form but for the modal
        //instead we have to create our own button
        footer={false}
        // onOk={handleOk}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedItemForEdit(null);
          form.resetFields(); // Reset the form to clear previous data
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={selectedItemForEdit || { period: "", company: "", title: "", description: "" }}
        >
          <Form.Item name="image" label="Image">
            <input placeholder="Image" />
          </Form.Item>
          <Form.Item name="link" label="Link">
            <input placeholder="Link" />
          </Form.Item>
          <Form.Item name="title" label="Title">
            <input placeholder="Title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <input placeholder="Description" />
          </Form.Item>
          <div className="flex justify-end gap-3">
            <button className="bg-green-400 px-5 py-2">
              {selectedItemForEdit ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AdminCourses;
