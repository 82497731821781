import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Button, Checkbox, Form, Input, Tabs } from "antd";
import AdminIntro from "./AdminIntro";
import AdminAbout from "./AdminAbout";
import Experiences from "./AdminExperiences";
import { useSelector } from "react-redux";
import Projects from "./AdminProjects";
import Courses from "./AdminCourses";
import { useNavigate } from "react-router-dom";

function Admin() {
  const { portfolioData } = useSelector((state) => state.root);
  const navigate = useNavigate();
  //if there is no token in the local storage we
  //will send the user back to login page
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/admin-login";
    }
  }, []);

  const onChange = (key) => {
    // console.log(key);
  };

  // const test =(
  //   <form>
  //     <div>
  //       <label htmlFor="name">Name:</label>
  //       <input
  //         type="text"
  //         id="name"
  //         name="name"
  //         placeholder="Enter your name"
  //         className="border-2 p-2 w-full"
  //       />
  //     </div>
  //     <div className="mt-4">
  //       <label htmlFor="email">Email:</label>
  //       <input
  //         type="email"
  //         id="email"
  //         name="email"
  //         placeholder="Enter your email"
  //         className="border-2 p-2 w-full"
  //       />
  //     </div>
  //     <div className="mt-4">
  //       <label htmlFor="message">Message:</label>
  //       <textarea
  //         id="message"
  //         name="message"
  //         placeholder="Enter your message"
  //         className="border-2 p-2 w-full"
  //         rows="4"
  //       />
  //     </div>
  //     <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2">
  //       Submit
  //     </button>
  //   </form>
  // );

  const items = [
    {
      key: "1",
      label: "Intro",
      children: <AdminIntro />,
    },
    {
      key: "2",
      label: "About",
      children: <AdminAbout />,
    },
    {
      key: "3",
      label: "Experiences",
      children: <Experiences />,
    },
    {
      key: "4",
      label: "Projects",
      children: <Projects />,
    },
    {
      key: "5",
      label: "Courses",
      children: <Courses />,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    // Redirect to login page
    navigate("/admin-login");
  };

  return (
    <div>
      <Header />
      <div className="flex gap-10 items-center px-5 pt-5 justify-between">
        <div className="flex gap-10 items-center">
          <h1 className="text-4xl font-bold cursor-pointer">Portfolio Admin</h1>
          <div className="w-60 h-[1px] bg-gray-500"></div>
        </div>
        <h1
          className="text-xl cursor-pointer border bg-red-600 px-5 py-2"
          onClick={handleLogout}
        >
          Logout
        </h1>
      </div>
      {/* ni rules untuk make sure portfolioData fully loaded from mongodb
      kalau tak letak nanti error sebab portfolioData ni fetch asynchronuously */}
      {portfolioData && (
        <div className="p-5 pt-5 font-black tabs">
          {/* Move Tabs component here */}
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      )}
    </div>
  );
}

export default Admin;
